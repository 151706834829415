import {
  LNC_ESPACE,
  LncMonCompteClientService,
} from "@odw/lnc-services/js-services/api-client/lnc-mon-compte-client.service";
import { huitabSettings } from "../services/settings-loader";
import CartoDelestageService from "./carto-delestage.service";
import MesAutorisationsCollocService from "./mes-autorisations-colloc.service";
import MesCollectivitesService from "./mes-collectivites.service";
import MesTravauxCollocService from "./mes-travaux-colloc.service";
import PassageHiverService from "./passage-hiver.service";
import MesTachesApiClientService from './mes-taches.api.client.service'
import MesPrmsEntrepriseService from './mes-prms-colloc.service'

const monCompteClientService = new LncMonCompteClientService(
  huitabSettings.url.mon_compte_client_api_url,
  LNC_ESPACE.COLLECTIVITE
);
const mesCollectivitesService = new MesCollectivitesService(huitabSettings.url.mes_collectivites_api_url);
const mesTravauxApiService = new MesTravauxCollocService(huitabSettings.url.mes_travaux_colloc_api_url);
const passageHiverService = new PassageHiverService(huitabSettings.url.mes_collectivites_api_url);
const mesAutorisationsCollocService = new MesAutorisationsCollocService(
  huitabSettings.url.mes_autorisations_colloc_api_url
);
const mesTachesService = new MesTachesApiClientService(huitabSettings.url.mes_taches_api_url);
const mesPrmsEntrepriseService = new MesPrmsEntrepriseService(huitabSettings.url.mes_prms_entreprise_api_url);
const cartoDelestageService = new CartoDelestageService(huitabSettings.url.carto_delestage_api_url);

export {
  cartoDelestageService,
  mesAutorisationsCollocService,
  mesCollectivitesService,
  mesTravauxApiService,
  monCompteClientService,
  passageHiverService,
  mesTachesService,
  mesPrmsEntrepriseService
};
