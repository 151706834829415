import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";
import { StatutDemande } from "./api-client-enums";

/**
 * Service pour réaliser les appels à l'api personne colloc.
 */
export default class MesCollectivitesService extends LncBaseApiClientService {
  /**
   * Action pour récupérer le périmètre de la personne.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @returns {Promise<PerimetreDto[]>} La promesse d'une réponse.
   */
  getPerimetre(cnAlex) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/personnes/" + cnAlex + "/perimetres")
        .then(response => {
          if (response.status === 204) {
            resolve([]);
          } else {
            resolve(response.content);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Action pour récupérer le nombre de demandes à l'état {@type StatutDemande.ENCOURS}.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @returns {Promise<number>} La promesse d'une réponse.
   */
  getNbDemandesEnCours(cnAlex) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/personnes/" + cnAlex + "/demandes?statut=" + StatutDemande.ENCOURS.name)
        .then(response => {
          if (response.status === 204) {
            resolve(0);
          } else {
            resolve(response.content.length);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }
}
