import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";

/**
 * Service pour réaliser les appels à l'api entreprise client.
 */
export default class MesPrmsEntrepriseService extends LncBaseApiClientService {

  /**
   * Action pour exporter les prms delestes de l'utilisateur
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @param listSiren {void|*} Liste des SIRENs associée aux prms à exporter
   * @returns {Promise<TacheDto[]>} La promesse d'une réponse.
   */
  exportPrmsDelestes(cnAlex, listSiren, dateformated) {
    return new Promise((resolve, reject) => {
      this.post("/api/private/v1/personnes/" + cnAlex + "/demande-prm-delestes", {
        sirens: listSiren, // liste des sirens (doit être un tableau de chaînes de 9 chiffres)
        dateDemande: dateformated, // date en format string
        idContext: 'PCLC' //context id pour COLLOC
      })
        .then((response) => {
          if (response.status === 204) {
            resolve([]);
          } else {
            resolve(response.content);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }
}