import { monCompteClientService } from "../api-client";
import { mesCollectivitesService } from "../api-client/index";
import { ComponentHelper } from './component-helper'
import { ModalTypes } from './enums.js'
import { PageManager } from './page-manager'
import { huitabSettings, PageTypes } from './settings-loader'
import { StoreManager } from './store-manager'
import { TagCommanderManager } from './tag-commander-manager'

/**
 * Service pour controller l'utilisateur et son parcours sur le portail colloc.
 */
class HuitabParcoursController {
  /**
   * Initialise une instance de type {@type HuitabParcoursController}.
   */
  constructor() {
  }

  /**
   * Action pour charger les informations de l'utilisateur.
   * En cas d'erreur la promesse n'est pas tenue et l'utilisateur est redirigé :
   *  - url de vie des services ko => redirection page de maintenance
   *  - info personne connectée pas disponible => redirection page de connexion
   *  - info du compte indisponible => redirection page service indisponible
   * @returns {Promise<UserInstance>} la promesse d'une réponse aves les informations de la personne connectée.
   */
  loadUserInstance() {
    const alivePromises = [monCompteClientService.isAlive(), mesCollectivitesService.isAlive()];

    return new Promise((resolve, reject) => {
      Promise.all(alivePromises).then(() => {
        monCompteClientService.getUserInfosV2().then(userInfoDto => {

            if (!userInfoDto.compteFinalise) {
              resolve({
                cnAlex: userInfoDto.cnAlex,
                userInfoDto: userInfoDto,
                perimetre: [],
                nbDemandesEnCours: 0,
              });
              return; // exit resolve
            }

            const promisesArray = [
              mesCollectivitesService.getPerimetre(userInfoDto.cnAlex),
              mesCollectivitesService.getNbDemandesEnCours(userInfoDto.cnAlex),
            ];

            Promise.allSettled(promisesArray).then(results => {
              const perimetres = results[0];
              const nbDemandesEnCours = results[1];

              // Sortie si le compte est finalisé mais on n'a pas récupéré le périmètre
              // ou si le compte est finalisé mais on n'a pas de périmètres et on n'a pas récupéré le nb de demandes en cours
              if (perimetres.status !== "fulfilled" || (perimetres.value.length === 0 && nbDemandesEnCours.status !== "fulfilled")) {
                TagCommanderManager.tagPage({
                  page: "service_indisponible",
                });

                PageManager.redirectTo(PageTypes.SERVICE_INDISPONIBLE);
                reject("service indisponible");
                return; // exit reject
              }

              resolve({
                cnAlex: userInfoDto.cnAlex,
                userInfoDto: userInfoDto,
                perimetre: perimetres.value,
                nbDemandesEnCours: nbDemandesEnCours.status === "fulfilled" ? nbDemandesEnCours.value : 0,
              });
            });
        }).catch(response => {
          // 403 Forbidden => redirection vers Page inaccessible
          if (response?.status === 403) {
            PageManager.redirectTo(PageTypes.PAGE_INACCESSIBLE);
            reject("info consentenement non donnée");
            return;
          }

          // 401 Unauthorized ou erreur CORS (status undefined ou status = 0) => redirection vers la mire Alex
          if (response?.status === 401 || !response?.status) {
            if (StoreManager.wasConnected()) {
              // Si on avait un utilisateur connecté => tag "Deco forcée"
              TagCommanderManager.tagPage({ 
                page: "deconnexion_involontaire", 
              });
            }
            StoreManager.delete();
            window.location.href = huitabSettings.envs.base_url + "/authenticate?target=" + window.location.href;
            reject("info personne connectée indisponible");
            return;
          }

          // Autre erreur => redirection vers la page Service indisponible
          PageManager.redirectTo(PageTypes.SERVICE_INDISPONIBLE);
          reject("service indisponible");
        });
      }).catch(() => {
        TagCommanderManager.tagPage({
          page: "service_indisponible",
        });
        PageManager.redirectTo(PageTypes.SERVICE_INDISPONIBLE);
        reject("service indisponible");
      });
    });
  }

  /**
   * Action pour renvoyer l'utilisateur sur le parcours en fonction des valeurs de {@param userInstance}.
   * @param userInstance {UserInstance} Les informations de la personne connectée.
   * @returns {boolean} Une valeur indiquant si la redirection a été enclenchée.
   */
  redirectionParcours(userInstance) {
    if (this._isPageAlwaysAccessible()) {
        return false;
    }

    // userInstance.perimetre.length correspond au nombre de demandes validées de l'utilisateur
    if (PageManager.current === PageTypes.PLAN_DU_SITE && !userInstance.perimetre.length) {
        if (userInstance.nbDemandesEnCours) {
            return PageManager.redirectTo(PageTypes.DEMANDES);
        }
        return PageManager.redirectTo(PageTypes.CREATION_COMPTE);
    }
    if (PageManager.current === PageTypes.CREATION_COMPTE && userInstance.userInfoDto.compteFinalise) {
      return PageManager.redirectTo(PageTypes.GESTION_COMPTE);
    }
    if (
      !userInstance.userInfoDto.compteFinalise &&
      PageManager.current !== PageTypes.CREATION_COMPTE
    ) {
      return PageManager.redirectTo(PageTypes.CREATION_COMPTE);
    }
    if (
      !userInstance.perimetre.length &&
      PageManager.current !== PageTypes.CREATION_COMPTE &&
      PageManager.current !== PageTypes.GESTION_COMPTE &&
      PageManager.current !== PageTypes.DEMANDES &&
      PageManager.current !== PageTypes.AJOUT_PERIMETRE &&
      PageManager.current !== PageTypes.DESACTIVATION_COMPTE
    ) {
      if (userInstance.nbDemandesEnCours > 0) {
        return PageManager.redirectTo(PageTypes.DEMANDES);
      }
      return PageManager.redirectTo(PageTypes.AJOUT_PERIMETRE);
    }
    return false;
  }

  /**
   * Action pour activer les modals en fonction des informations du compte.
   * @param userInfoDto {UserInfoDto} Les informations du compte.
   * @returns {boolean} Une valeur indiquant si une modal est activée.
   */
  promptModal(userInfoDto) {

      if (!userInfoDto.dateCGU || userInfoDto.dateCGU.getTime() < huitabSettings.last_cgu_update.getTime()) {
        ComponentHelper.openModal(ModalTypes.CGU);
        return true;
      }
    return false;
  }

  /**
   * Retourne true si la page courante fait partie des pages toujours accessibles (auxquelles on applique pas la redirection forcées).
   *
   * La liste des pages toujours accessibles:
   * - Liens du footer.
   *
   * @returns {boolean}
   * @private
   */
  _isPageAlwaysAccessible() {
    // On récupère les pages du footer.
    const listUrlsFooterAlwaysAccessible = [
      PageTypes.MENTIONS_LEGALES.path,
      PageTypes.CGU.path,
      PageTypes.ACCESSIBILITE.path,
    ];

    return listUrlsFooterAlwaysAccessible.includes(PageManager.current.path);
  }
}

const ParcoursController = new HuitabParcoursController(huitabSettings.envs.base_url);
export { ParcoursController };
