import { huitabSettings } from "./settings-loader";

/**
 * Retourne TRUE si on est en periode hivernale.
 * La date de début et de fin de cette période est défini dans l'inventaire.
 *
 * @returns {boolean}
 */
function siOnEstEnPeriodeHivernale() {
  const debutHiver = _convertMonthDayToNumber(huitabSettings.feature_flipping.date_debut_passage_hiver_mm_jj);
  const finHiver = _convertMonthDayToNumber(huitabSettings.feature_flipping.date_fin_passage_hiver_mm_jj);
  const isMemeAnnee = huitabSettings.feature_flipping.annee_periode_hiver_identique;
  const today = parseInt(new Date().getMonth() + 1 + "" + ("0" + new Date().getDate()).slice(-2));

  if (isMemeAnnee && today >= debutHiver && today <= finHiver) {
    // Si on est sur la même année, on regarde si la date d'aujourd'hui est comprise entre la date de début et de la fin.
    return true;
  }
  if (!isMemeAnnee && (today <= finHiver || today >= debutHiver)) {
    // Si on n'est pas sur la même année, c'est un OU qui est utilisé
    return true;
  }
  // Sinon
  return false;
}

/**
 * Convertit un string sous la forme "MM-JJ" avec separator "-"
 * @param dateMoisJour exemple "01-22".
 * @returns {number} exemple: 122.
 * @private
 */
function _convertMonthDayToNumber(dateMoisJour) {
  const splitted = dateMoisJour.split("-");
  return parseInt(splitted[0] + splitted[1]);
}

export { siOnEstEnPeriodeHivernale };
