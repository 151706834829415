import { BaseCookie } from "./base-cookie";
import { HuitabProfiles } from "./enums";
import { huitabSettings } from "./settings-loader";

/**
 * Cookie portail
 */
class HuitabCookiePortail extends BaseCookie {
  /**
   * Initialise une instance de type {@type HuitabCookiePortail}.
   */
  constructor() {
    super("8ab_portail_colloc", location.hostname, huitabSettings.envs.profile === HuitabProfiles.PROD);
  }

  /**
   * Get l'identifiant interne de l'utilisateur depuis le cookie
   * @return {string|null} Le siren sélectionné
   */
  get interneId() {
    return this.get("interne_id");
  }

  /**
   * Set l'identifiant interne de l'utilisateur dans le cookie
   * @param {string} value Le siren sélectionné
   */
  set interneId(value) {
    this.set("interne_id", value);
  }

  /**
   * Get le siren sélectionné depuis le cookie
   * @return {string|null} Le siren sélectionné
   */
  get selectedSiren() {
    return this.get("selected_siren");
  }

  /**
   * Set le siren sélectionné dans le cookie
   * @param {string} value Le siren sélectionné
   */
  set selectedSiren(value) {
    this.set("selected_siren", value);
  }

  /**
   * Get une valeur indiquant si la migration doit reprendre plus tard.
   * @return {string|null} Une valeur indiquant si la migration doit reprendre plus tard.
   */
  get migrationAskLater() {
    return this.get("migration_ask_later");
  }

  /**
   * Set une valeur indiquant si la migration doit reprendre plus tard.
   * @param {string} value La valeur indiquant si la migration doit reprendre plus tard.
   */
  set migrationAskLater(value) {
    this.set("migration_ask_later", value);
  }

  /**
   * Get une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @return {string|null} Une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  get externalLinkAskLater() {
    return this.get("external_link_ask_Later");
  }

  /**
   * Set valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @param {string} value La valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  set externalLinkAskLater(value) {
    this.set("external_link_ask_Later", value);
  }

  /**
   * Get la date sélectionnée dans le bandeau météo.
   * @return {string|null} La date sélectionnée dans le bandeau météo.
   */
  get bandeauMeteoDateSelectionnee() {
    return this.get("bandeau_meteo_date_selectionnee");
  }

  /**
   * Set la date sélectionnée dans le bandeau météo.
   * @param {string} value La date sélectionnée dans le bandeau météo.
   */
  set bandeauMeteoDateSelectionnee(value) {
    this.set("bandeau_meteo_date_selectionnee", value);
  }

  /**
   * Get la date sélectionnée formatée dans le bandeau météo.
   * @return {string|null} La date sélectionnée dans le bandeau météo.
   */
  get bandeauMeteoDateSelectionneeFormatee() {
    return this.get("bandeau_meteo_date_selectionnee_formatee");
  }

  /**
   * Set la date sélectionnée formatée dans le bandeau météo.
   * @param {string} value La date sélectionnée dans le bandeau météo.
   */
  set bandeauMeteoDateSelectionneeFormatee(value) {
    this.set("bandeau_meteo_date_selectionnee_formatee", value);
  }

  /**
   * Get la couleur sélectionnée dans le bandeau météo.
   * @return {string|null} La couleur sélectionnée dans le bandeau météo.
   */
  get bandeauMeteoCouleur() {
    return this.get("bandeau_meteo_couleur");
  }

  /**
   * Set la couleur sélectionnée dans le bandeau météo.
   * @param {string} value La couleur sélectionnée dans le bandeau météo.
   */
  set bandeauMeteoCouleur(value) {
    this.set("bandeau_meteo_couleur", value);
  }

  /**
   * Get si le compte est multi-collectivités (périmètre intégrant plusieurs siren)
   * @return {string|null} Le périmètre du compte comprend plusieurs siren (compte multi-collectivités)
   */
  get isMultiCollectivites() {
    return this.get("is_multi_collectivites");
  }

  /**
   * Set si le compte est multi-collectivités (périmètre intégrant plusieurs siren)
   * @param {string} value Le périmètre du compte comprend plusieurs siren (compte multi-collectivités)
   */
  set isMultiCollectivites(value) {
    this.set("is_multi_collectivites", value);
  }
}

const CookiePortail = new HuitabCookiePortail();
export { CookiePortail };
