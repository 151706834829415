/**
 * Les types d'environnement du portail colloc
 */
const HuitabProfiles = {
    DEV: "dev",
    PROD: "prod",
};

/**
 * Les modals du portail colloc
 */
const ModalTypes = {
    CGU: "#cguModal",
    LOGOUT: "#modal-deconnexion",
    EXTERNAL_LINK: "#modal-external-link",
};

/**
 * Évènements du store
 */
const StoreKeys = {
    USER_INFO: "userInfoDto",
    PERIMETRE: "perimetre",
    NB_DEMANDES_EN_COURS: "nbDemandesEnCours",
    SELECTED_SIREN: "selectedSiren",
    BANDEAU_METEO: "bandeauMeteo",
    TACHES_TEMP: "tachesTemp",
    TACHES: "taches",
};

/**
 * Les modals ecowatt
 */
const ModalTypesEcoWattFeatureFlipping = {
    ROUGE_ORANGE_FEATURE_FLIPPING_OFF: "modal-ecowatt-rouge-orange-feature-flipping-off",
    ROUGE_ORANGE_FEATURE_FLIPPING_ON: "modal-ecowatt-rouge-orange-feature-flipping-on",
};

/**
 * Constantes pour le portail
 */
const HuitabConstantes = {
    SIREN_ALL: "all",
};

/**
 * Constantes pour les messages à afficher dans les tuiles du bandeau météo
 */
const MessageEcoWatt = {
    VERT: "Signal vert",
    ROUGE: "Système électrique très tendu",
    ORANGE: "Système électrique tendu",
    GRIS: "Prévisions à venir à 17h30 (12h30 le vendredi)",
};

/**
 * Couleurs des tuiles de la météo
 */
const ColorEcoWatt = {
    VERT: "VERT",
    ROUGE: "ROUGE",
    ORANGE: "ORANGE",
    GRIS: "GRIS",
};

/**
 * Constantes pour les messages à afficher dans la modale de demande de prms delestés
 */
const MessageDayInfo = {
    DAY_0_HOUR: "8h00",
    DAY_1_HOUR: "20h00",
    DAT_2_HOUR: "17h00",
    DAY_3_HOUR: "17h00",
    INFO_DAY_0: "Veuillez noter que le plan de délestage a été mis à jour vers ",
    INFO_DAY_1_2: "Veuillez noter que le plan de délestage sera mis à jour vers ",
    INFO_DAY_3: "Veuillez noter qu'un plan de délestage sera mis à votre disposition vers ",
    DESCRIPTION: "Vous pourrez accéder uniquement aux données des compteurs de votre périmètre titulaire.",
    MSG_ATTENTION: "Attention :  un téléchargement peut produire un fichier vide si aucun de vos compteurs n’est impacté."
}

export { ColorEcoWatt, MessageEcoWatt, HuitabConstantes, HuitabProfiles, ModalTypes, ModalTypesEcoWattFeatureFlipping, StoreKeys, MessageDayInfo  }
