import ModalBase from "./modal-base";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import { monCompteClientService } from "../../api-client/index";
import { StoreManager } from "../../services/store-manager";
import { PageManager } from "../../services/page-manager";

/**
 * La modal pour les CGU
 */
export default class ModalCgu extends ModalBase {
  /**
   * Initialise une nouvelle instance de type {@type ModalCgu}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir à l'ouverture de la modal
   * @override
   */
  _onShowModal() {
    TagCommanderManager.tagPage({
      chapitre_1: "connexion",
      page: "cgu",
    });
  }

  /**
   * Action pour réagir au clic CGU OK.
   * @override
   */
  _onAccept() {
    const today = new Date();
    monCompteClientService.postDateCguWithEspace(StoreManager.cnAlex, today, 'COLLECTIVITE')
      .then(() => this._closeModal())
      .catch((e) => {
        console.error(e)
      });
  }

  /**
   * Action pour réagir au clic CGU NOK.
   * @override
   */
  _onDismiss() {
    PageManager.logout();
  }
}
