/**
 * Required Theme
 */
require("./require-theme.js");

/**
 * Required Javascript
 */
require("@ungap/custom-elements");
require("@fortawesome/fontawesome-free/js/all.js");
require("bootstrap/dist/js/bootstrap.bundle.js");

require("../js/components/declare-components.js");
require("../js/app-main.js");
