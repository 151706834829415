import { HuitabModal } from '../../js/components/common/huitab-modal'

export default class ModalEcowattVert extends HuitabModal {
  /**
   * Initialise une nouvelle instance de type {@type ModalTBD}.
   */
  constructor() {
    super("modal-ecowatt-vert");
  }

  show() {
    super.show();
  }
}