import { passageHiverService } from '../../js/api-client/index'
import {
  ColorEcoWatt,
  MessageDayInfo,
  MessageEcoWatt,
  ModalTypesEcoWattFeatureFlipping,
  StoreKeys,
} from '../../js/services/enums'
import { PageManager } from '../../js/services/page-manager'
import { siOnEstEnPeriodeHivernale } from '../../js/services/passage-hiver'
import { huitabSettings, PageTypes } from '../../js/services/settings-loader'
import { StoreManager } from '../../js/services/store-manager'
import { TagCommanderManager } from '../../js/services/tag-commander-manager'
import ModalEcowattVert from './modal-ecowatt-vert'
import ModalEcowattOrangeRouge from './modal-ecowatt-rouge-orange'
import LncWebSelect from './lnc-web-select'
import ModalConfirmShowTasks from './modal-confirm-show-tasks'

require("../require");
require("/node_modules/chart.js/dist/Chart.js");
require("./page.scss");
require('./modal-ecowatt.scss');

// #region Configuration modal téléchargement des compteurs dans le bandeau meteo ecowatt
export const modalEcowattVert = new ModalEcowattVert()
export const modalEcowattRougeOrangeFeatureFlippingOff = new ModalEcowattOrangeRouge(ModalTypesEcoWattFeatureFlipping.ROUGE_ORANGE_FEATURE_FLIPPING_OFF)
export const modalEcowattRougeOrangeFeatureFlippingOn = new ModalEcowattOrangeRouge(ModalTypesEcoWattFeatureFlipping.ROUGE_ORANGE_FEATURE_FLIPPING_ON)
export const componentSelectCollectivite = new LncWebSelect()
export const modalConfirmShowTasks = new ModalConfirmShowTasks();
const isFeatureFlippingEcowattOn = huitabSettings.feature_flipping.feature_flipping_telechargement_prms_delestes
const isFeatureFlippingAfficherBandeauMeteo = huitabSettings.feature_flipping.feature_flipping_afficher_bandeau_meteo

let signalsPage = [];

if(isFeatureFlippingAfficherBandeauMeteo) {
  if (isFeatureFlippingEcowattOn) {
    StoreManager.subscribe(StoreKeys.SELECTED_SIREN, () => {
      _initialiseCollectiviteSelectMenu(StoreManager.perimetre)
    });
    window.addEventListener("load", () => {
      document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').getElementsByClassName('close-button').item(0).addEventListener('click', () => modalEcowattRougeOrangeFeatureFlippingOn.hideDismiss())
      document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').getElementsByClassName('download-button').item(0).addEventListener('click', () => modalEcowattRougeOrangeFeatureFlippingOn.requestExportPrmsDelestes())
    });

  }
  document.getElementById("afficher-zones-impactees").addEventListener("click", afficherZonesImpactees);
  document.getElementById("telecharger-prm").addEventListener("click", afficherPopinTelechargementPrm);
  document.getElementById("btn-redirect-monecowatt").addEventListener("click", addTagAndredirectToMonEcowatt);
}

StoreManager.subscribe(StoreKeys.BANDEAU_METEO, () => {
  if (isFeatureFlippingAfficherBandeauMeteo) {
    _initialiseBandeauMeteo();
  }
});

if (siOnEstEnPeriodeHivernale()) {
  // Affiche l'encart quand on est dans l'interval de l'hiver
  document.querySelector("#encartBandeau").hidden = false;
} else {
  // Enlève l'encart quand on est en dehors de l'interval de l'hiver
  document.querySelector("#encartBandeau").hidden = true;
}

// Feature flipping rationalisation mes-demandes-colloc
if (huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled) {
  document
    .querySelector("div[is='micro-app-widget-aide-et-contact']")
    .setAttribute("data-url", "/mes-collectivites/private-widget-interlocuteurs");
  document
    .querySelector("div[is='micro-app-widget-aide-et-contact']")
    .setAttribute("data-url-vie", "/mes-collectivites/actuator/info");
} else {
  document
    .querySelector("div[is='micro-app-widget-aide-et-contact']")
    .setAttribute("data-url", "/mes-demandes-colloc/private-widget-interlocuteurs");
  document
    .querySelector("div[is='micro-app-widget-aide-et-contact']")
    .setAttribute("data-url-vie", "/mes-demandes-colloc/actuator/info");
}

/* ********************************************* */
/* ****************** FONCTIONS **************** */
/* ********************************************* */
function afficherZonesImpactees() {
  TagCommanderManager.tagClick({
    chapitre_1: "Ecowatt",
    chapitre_2: "signal_" + StoreManager.bandeauMeteoCouleur.toLowerCase(),
    chapitre_3: "page_accueil",
    page: "voir_les_zones_impactees",
    type: "navigation",
  });

  if (StoreManager.bandeauMeteoCouleur === ColorEcoWatt.VERT) {
    document.getElementById("modal-ecowatt-vert").setAttribute("title","Cartographies des zones sujettes aux délestages durant les prochains jours :");
    document.getElementById("modal-ecowatt-vert").getElementsByClassName("text_modal_ecowatt_vert").item(0).innerHTML = "Pas de cartographie de délestage pour le jour sélectionné.";
    modalEcowattVert.show();
  } else {
    //Give browser time to load the new tag
    setTimeout(function () {
      PageManager.redirectTo(PageTypes.CARTE_ZONES_DELESTEES);
    }, 200);
  }
}

function _initialiseBandeauMeteo () {
  passageHiverService
    .getInfoDaysEcowatt()
    .then(results => {
      if (!results.length) {
        throw new Error("Aucune information retournée");
      }

      // Création de l'élement bandeau météo
      const lncWebCardsMeteo = document.createElement("lnc-web-cards-meteo");
      const bandeauMeteo = document.querySelector("#bandeauMeteo");

      // Gestion de l'affichage des boutons sous le bandeau
      document.querySelector(".btns-selected-card").hidden = !results.some(infoDayEcoWat => {
        return infoDayEcoWat.color === ColorEcoWatt.ROUGE || infoDayEcoWat.color === ColorEcoWatt.ORANGE;
      });

      // S'il n'y a pas de valeur dans le store, alors on initialise les valeurs avec la première case (sélectionnée par défaut)
      if (
        !StoreManager.bandeauMeteoCouleur ||
        !StoreManager.bandeauMeteoDateSelectionnee ||
        !StoreManager.bandeauMeteoDateSelectionneeFormatee
      ) {
        const dateFormatee = new Date(results[0].date).toLocaleString("fr-FR", {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        const dateFormateeCapitalisee = dateFormatee.charAt(0).toUpperCase() + dateFormatee.slice(1);
        storeEcowattInfos(results[0].color, results[0].date, dateFormateeCapitalisee);
      }

      // Suppression de la prop "message" des résultats qui n'est pas utilisée & initialisation de la date sélectionnée
      const signals = JSON.parse(JSON.stringify(results))
      signalsPage = signals;
      signals.forEach(signal => {
        signal.isSelected = StoreManager.bandeauMeteoDateSelectionnee === signal.date;
        delete signal.message;
      });

      lncWebCardsMeteo.setAttribute("data", JSON.stringify(signals));
      lncWebCardsMeteo.addEventListener("onCardInfoSend", event => {
        const infoDayEcoWatSelected = JSON.parse(event.detail);
        storeEcowattInfos(infoDayEcoWatSelected.color, infoDayEcoWatSelected.date, infoDayEcoWatSelected.fullDate);
        //taggage
        const dateFormatee = new Date(infoDayEcoWatSelected.date)
          .toLocaleString("fr-FR", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })
          .replace(/[/]+/g, "-");

        TagCommanderManager.tagClick({
          chapitre_1: "Ecowatt",
          chapitre_2: dateFormatee,
          chapitre_3: "page_accueil",
          page: "signal_" + infoDayEcoWatSelected.color.toLowerCase(),
          type: "action",
        });
      });

      bandeauMeteo.insertBefore(lncWebCardsMeteo, bandeauMeteo.firstChild);
    })
    .catch(() => {
      document.querySelector("#serviceIndisponibleMeteo").hidden = false;
      if (document.querySelector("lnc-web-cards-meteo") != null) {
        document.querySelector("lnc-web-cards-meteo").hidden = true;
      }
      document.querySelector(".btns-selected-card").hidden = true;
    })
    .finally(() => {
      document.querySelector("#bandeauMeteo").hidden = false;
    });
}

function _initialiseCardMeteoInModal () {
  let message = '';
  if (StoreManager.bandeauMeteoCouleur === ColorEcoWatt.ORANGE) {
    message = MessageEcoWatt.ORANGE
  } else if (StoreManager.bandeauMeteoCouleur === ColorEcoWatt.ROUGE) {
    message = MessageEcoWatt.ROUGE
  }

  const lncWebCardMeteoSelected = Object.assign(document.createElement('lnc-web-card-meteo'), { id: 'lnc-web-card-meteo', message: message, date: StoreManager.bandeauMeteoDateSelectionneeFormatee, color: StoreManager.bandeauMeteoCouleur })
  const element = document.getElementById('tuile-content')
  const labelJourElement = document.createElement('p')
  labelJourElement.classList.add('lnc_text_2')
  labelJourElement.classList.add('bold')
  labelJourElement.setAttribute('id', 'label_jour')
  labelJourElement.innerHTML = 'Jour'

  element.innerHTML = ''
  element.appendChild(labelJourElement)
  element.appendChild(lncWebCardMeteoSelected)
}

function _getTextToDisplay() {
  let hourToDisplay
  let textToDisplay
  switch (StoreManager.bandeauMeteoDateSelectionnee) {
    case signalsPage[0].date:
      hourToDisplay = MessageDayInfo.DAY_0_HOUR
      textToDisplay = MessageDayInfo.INFO_DAY_0
      break
    case signalsPage[1].date:
      hourToDisplay = MessageDayInfo.DAY_1_HOUR
      textToDisplay = MessageDayInfo.INFO_DAY_1_2
      break
    case signalsPage[2].date:
      hourToDisplay = MessageDayInfo.DAT_2_HOUR
      textToDisplay = MessageDayInfo.INFO_DAY_1_2
      break
    case signalsPage[3].date:
      hourToDisplay = MessageDayInfo.DAY_3_HOUR
      textToDisplay = MessageDayInfo.INFO_DAY_3
      break
    default:
      break
  }
  return textToDisplay + hourToDisplay + '.<br/>' + MessageDayInfo.DESCRIPTION + '<br/>' + MessageDayInfo.MSG_ATTENTION;
}

/**
 * Fonction initialisant les options du dropdown menu à partir des collectivites
 * @param {PerimetreDto[]} perimetres Les périmetres de l'utilisateur
 */
function _initialiseCollectiviteSelectMenu (perimetres) {
  const perimetresJSON = perimetres?.map(({ localite, siren, role }) => ({ label: localite + ' - ' + role, value: siren }));
  const selectComponent = document.getElementById("dropdown-select-collectivite");

  perimetresJSON.push({ label: "Toutes les collectivités", value: "All" });
  selectComponent.setAttribute('data', JSON.stringify(perimetresJSON));
  const selectedCollectivites = StoreManager.getSelectedCollectivites();
  componentSelectCollectivite.writeValue(selectedCollectivites.length > 1 ? "All" : selectedCollectivites.siren)
}

function afficherPopinTelechargementPrm() {
  if (isFeatureFlippingAfficherBandeauMeteo) {
    TagCommanderManager.tagClick({
      chapitre_1: "Ecowatt",
      chapitre_2: "signal_" + StoreManager.bandeauMeteoCouleur.toLowerCase(),
      chapitre_3: "page_accueil",
      page: "telecharger_la_liste_du_jour",
      type: "action",
    });

    if (StoreManager.bandeauMeteoCouleur === ColorEcoWatt.VERT) {
      document.getElementById("modal-ecowatt-vert").setAttribute("title","Liste des compteurs sujets aux délestages durant les prochains jours :");
      document.getElementById("modal-ecowatt-vert").getElementsByClassName("text_modal_ecowatt_vert").item(0).innerHTML = "Pas de PRM impacté pour le jour sélectionné.";
      modalEcowattVert.show();
    } else {
      if (isFeatureFlippingEcowattOn) {
        _initialiseCardMeteoInModal();
        document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').getElementsByClassName('info_day_modal_ecowatt_rouge-orange').item(0).innerHTML = _getTextToDisplay();
        modalEcowattRougeOrangeFeatureFlippingOn.show()
      } else {
        modalEcowattRougeOrangeFeatureFlippingOff.show();
      }
    }
  }
}

function addTagAndredirectToMonEcowatt() {
  TagCommanderManager.tagClick({
    chapitre_1: "Ecowatt",
    chapitre_2: "signal_" + StoreManager.bandeauMeteoCouleur.toLowerCase(),
    chapitre_3: "page_accueil",
    page: "tout_comprendre_sur_les_signaux",
    type: "navigation",
  });

  window.open("https://www.monecowatt.fr/", "_blank");
}

function storeEcowattInfos(couleur, date, dateFormatee) {
  StoreManager.bandeauMeteoCouleur = couleur;
  StoreManager.bandeauMeteoDateSelectionnee = date;
  StoreManager.bandeauMeteoDateSelectionneeFormatee = dateFormatee;
}
