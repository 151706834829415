import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";

/**
 * Service pour réaliser les appels à l'api de mes-autorisations-colloc
 */
export default class MesAutorisationsCollocService extends LncBaseApiClientService {
  /**
   * Retourne la liste des compétences actives
   * @param {string} siren Le siren pour lequel nous voulons récupérer les compétences actives
   * @returns {Promise<CompetenceSirenDto>} La promesse d'une réponse.
   */
  getCompetencesActivesSiren(siren) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/collectivites/" + siren + "/competences?type=GT&niveau=COMMUNAL")
        .then(response => {
          resolve(response.content);
        })
        .catch(() => {
          reject();
        });
    });
  }
}
