import { ComponentHelper } from "../../services/component-helper";
import { ModalTypes, StoreKeys } from "../../services/enums";
import { StoreManager } from "../../services/store-manager";
import { HuitabTagCommanderManager, TagCommanderManager } from "../../services/tag-commander-manager";
import HuitabToast from './huitab-toast'
import { StatutTache } from '../../api-client/enums'
import { mesTachesService } from '../../api-client'
import { TacheManager } from '../../services/tache-manager'

/**
 * Composant pour le header du portail
 */
export default class HuitabHeader extends HTMLElement {
  /**
   * Composant lnc-task
   */
  lncTask;

  /**
   * Initialise une nouvelle instance de type {@type HuitabHeader}.
   */
  constructor() {
    super();
    this.lncTask = this.querySelector("lnc-web-task");

    if (this.lncTask) {
      this.lncTask.addEventListener("taskItemClick", this._onTaskItemClick.bind(this));
      this.lncTask.addEventListener("errorButtonClick", () => {
        HuitabToast.INSTANCE.toastme("Le service est indisponible, veuillez réessayer plus tard");
      });
    }
    StoreManager.subscribe(StoreKeys.USER_INFO, this._onInfoCompteChange.bind(this));
    StoreManager.subscribe(StoreKeys.NB_DEMANDES_EN_COURS, this._displayNbDemandesEnCours.bind(this));
    StoreManager.subscribe(StoreKeys.PERIMETRE, this._displayListeCollectivites.bind(this));
    StoreManager.subscribe(StoreKeys.TACHES, this._onTachesChanged.bind(this));

    // Au click du bouton burger en navigation mobile : affiche ou cache le menu
    this.querySelector("#navbar-toggler-icon").addEventListener("click", this._changeToggleIconClass.bind(this));

    // Changement de l'icône de la liste des collectivités au déploiement et repliement de la liste déroulante
    jQuery("#liste-collectivite").on("show.bs.dropdown", this._changeCollectiveListIconClass.bind(this));
    jQuery("#liste-collectivite").on("hidden.bs.dropdown", this._changeCollectiveListIconClass.bind(this));

    // Action click sur déconnexion mobile + desktop
    this.querySelectorAll(".user-logout").forEach(elem => {
      elem.addEventListener("click", () => {
        TagCommanderManager.tagClick({
          chapitre_1: "menu",
          chapitre_2: "gestion_de_compte",
          page: "deconnexion",
          type: "navigation",
        });
        ComponentHelper.openModal(ModalTypes.LOGOUT);
      });
    });
  }

  /**
   * Construction du fil d'Ariane en mode desktop
   * @param config {FilArianeConfig[]} La configuration pour le fil d'Ariane desktop
   */
  static setFilArianeDesktop(config) {
    const breadcrumb = document.querySelector("#huitab-header-breadcrumb-desktop");
    breadcrumb.innerHTML = "";
    config.forEach((item, index) => {
      const label = item.href ? `<a href="${item.href}">${item.label}</a>` : item.label;
      const css = index === 0 ? "board-title" : "breadcrumb-item";
      breadcrumb.insertAdjacentHTML("beforeend", `<li class="${css}">${label}</li>`);
    });
  }

  /**
   * Construction du fil d'Ariane en mode mobile
   * @param config {string} La configuration pour le fil d'Ariane mobile
   */
  static setFilArianeMobile(config) {
    const breadcrumb = document.querySelector("#huitab-header-breadcrumb-mobile");
    breadcrumb.innerText = config;
  }

  /**
   * Activation du lien du header
   * @param activeNavLinks {{activeNavLinksMobile: string[], activeNavLinksDesktop: string[]}} Objet contenant les listes des selecteurs CSS des liens à activer (mobile + desktop)
   */
  static setLienHeader(activeNavLinks) {
    // Reset des liens actifs
    const desktopNav = document.getElementById("desktop-nav");
    const mobileNav = document.getElementById("mobile-nav");
    [desktopNav, mobileNav].forEach(nav => {
      nav.querySelectorAll(".active").forEach(link => {
        link.classList.remove("active");
      });
    });

    // Activation des nouveaux liens actifs en mode desktop
    activeNavLinks.activeNavLinksDesktop.forEach(cssNavLinkDesktop => {
      this._activeHeaderLink(desktopNav, cssNavLinkDesktop);
    });

    // Activation des nouveaux liens actifs en mode mobile
    activeNavLinks.activeNavLinksMobile.forEach(cssNavLinkMobile => {
      this._activeHeaderLink(mobileNav, cssNavLinkMobile);
    });
  }

  /**
   * Ajoute les classes et attributs pour activer les liens du header
   * @param nav {HTMLElement} Le menu contenant les liens à activer (mobile ou desktop)
   * @param cssNavLink {string} La liste des liens à activer (selecteurs CSS)
   */
  static _activeHeaderLink(nav, cssNavLink) {
    const selectedCssNavLink = nav.querySelector("." + cssNavLink);
    selectedCssNavLink.classList.add("active");
    selectedCssNavLink.setAttribute("aria-current", "page");
  }

  /**
   * Afficher les infos du compte et gérer l'affichage des éléments des menus de navigation desktop & mobile
   * @param {UserInfoDto} userInfoDto Les informations du compte
   * @private
   */
  _onInfoCompteChange(userInfoDto) {
    /**
     * Node HTML pour afficher la bienvenue
     * @type {HTMLElement}
     */
    const nodeBienvenue = this.querySelector("#huitab-header-bienvenue");
    nodeBienvenue.innerText = "Bienvenue " + userInfoDto.prenom + " " + userInfoDto.nom;

    this._hideHeaderElements(userInfoDto);
  }

  /**
   * Gérer l'affichage des éléments du header selon le statut du compte
   * @param {UserInfoDto} userInfoDto Les informations du compte
   */
  _hideHeaderElements(userInfoDto) {
    if (
      !userInfoDto.compteFinalise || StoreManager.perimetre.length === 0
    ) {
      //Masquage des collectivites
      this.querySelector(".page-title-container").hidden = true;
      //Masquage de la barre de navigation principale
      this.querySelector(".region-nav-main").hidden = true;
      //Masquage du fil d'Ariane
      this.querySelector(".region-breadcrumb").hidden = true;

      //Masquage des éléments de la barre de navigation mobile
      this.querySelectorAll(
        `#mobile-nav .navbar-nav li a:not([href*='/hub.html?allEspace=true'], .user-logout)`
      ).forEach(anchor => {
        anchor.parentNode.hidden = true;
      });

      //Masquage des éléments de la barre de navigation desktop
      this.querySelectorAll(
        `#block-oab-bootstrap-4-account-menu a:not([href*='/hub.html?allEspace=true'], .user-logout)`
      ).forEach(anchor => {
        anchor.hidden = true;
      });
    }
  }

  /**
   * Méthode pour charger la liste des tâches de l'utilisateur
   * Action sur le changement de valeur pour la clé TACHES
   * @param taches {TacheGroupDto} liste des tâches
   * @private
   */
  _onTachesChanged(taches) {
    const taskItems = taches.liste.map((tache) => {
      return {
        id: tache.id,
        label: tache.libelle,
        status: tache.statut,
        typeTache: tache.type,
      };
    });

    const taskGroupModel = {
      statusOk: taches.statutOk,
      tasks: taskItems,
    };

    this.lncTask.setAttribute("data-task-group", JSON.stringify(taskGroupModel));
  }

  /**
   * Afficher les demandes en cours
   * @param {number} nbDemandes
   * @private
   */
  _displayNbDemandesEnCours(nbDemandes) {
    /**
     * Nodes HTML pour afficher les demandes en cours
     * @type {NodeList<HTMLElement>}
     */
    const nodeDemandesEncours = this.querySelectorAll(".huitab-header-demandes-encours");
    nodeDemandesEncours.forEach(element => {
      element.innerText = nbDemandes.toString();
    });
  }

  /**
   * Afficher les collectivités dans la liste déroulante
   * @param {PerimetreDto[]} perimetre
   * @private
   */
  _displayListeCollectivites(perimetre) {
    /**
     * Node HTML pour afficher les collectivités
     * @type {HTMLElement}
     */
    const nodeListeCollectivites = this.querySelector("#huitab-header-collectivite-liste");

    /**
     * Node HTML pour afficher la collectivité sélectionnée
     * @type {HTMLElement}
     */
    const nodeCollectiviteSelection = this.querySelector("#huitab-header-collectivite");

    //S'il n'y a qu'une seule collectivité associé au compte, on pré-sélectionne la collectivité
    if (perimetre.length == 1) {
      nodeListeCollectivites.innerHTML = "";
      StoreManager.selectedSiren = perimetre[0].siren;
    }

    // Construire la liste de collectivités
    perimetre.forEach(perimetreDto => {
      const text = perimetreDto.localite + " - " + perimetreDto.role;
      const displayText = text.length > 77 ? perimetreDto.localite : text;

      nodeListeCollectivites.insertAdjacentHTML(
        "beforeend",
        `<a href="" id="collectivite-${perimetreDto.siren}" class="dropdown-item" 
          data-siren="${perimetreDto.siren}" data-text="${displayText}"
          data-tag-click='{"chapitre_1": "menu", "chapitre_2": "tous_les_perimetres", 
          "page": "${HuitabTagCommanderManager.cleanText(displayText)}", "type": "navigation"}'
          >${text}</a>`
      );

      if (StoreManager.selectedSiren === perimetreDto.siren) {
        // Restaurer la collectivité sélectionnée
        nodeCollectiviteSelection.innerText = displayText;
      }
    });

    // Activer la collectivité sélectionnée
    this.querySelector(`#collectivite-${StoreManager.selectedSiren}`).classList.add("active");

    // Réaction au changement de collectivité sélectionnée
    nodeListeCollectivites.addEventListener("click", event => {
      event.preventDefault();

      /**
       * La node collectivité sélectionnée
       * @type {HTMLAnchorElement} collectivite
       */
      const selectedNode = event.target;

      // Traitement uniquement sur les balises anchor de la liste des collectivités
      if (selectedNode.tagName === "A") {
        // Basculer la classe active vers la nouvelle collectivité sélectionnée
        nodeListeCollectivites.querySelector(".active").classList.remove("active");
        selectedNode.classList.add("active");

        // Afficher la nouvelle collectivité sélectionnée
        nodeCollectiviteSelection.innerText = selectedNode.dataset.text;

        // Envoyer event tag commander de type click
        const tag = JSON.parse(selectedNode.dataset.tagClick);
        TagCommanderManager.tagClick(tag);

        // Mise à jour du store
        StoreManager.selectedSiren = selectedNode.dataset.siren;
      }
    });
  }

  /**
   * Callback au clic du bouton télécharger ou relancer dans le lnc-tasks
   * Si le statut de la tache est à 'TERMINE' alors on télécharge
   * Si le statut de la tache est à 'ECHOUE' alors on relance
   * @param event {CustomEvent<TaskMenuEvent>}
   * @private
   */
  _onTaskItemClick(event) {
    const tache = event.detail;
    if (tache.status === StatutTache.TERMINE) {
      mesTachesService
        .telechargerTache(StoreManager.cnAlex, tache.id)
        .then()
        .catch((erreur) => {
          HuitabToast.INSTANCE.toastme("Le téléchargement a échoué, veuillez réessayer plus tard");
        });
      TagCommanderManager.tagClick({
        chapitre_1: "vos_donnees_energetiques",
        chapitre_2: "vos_compteurs_prm",
        chapitre_3: "taches_en_cours",
        page: "telecharger",
        type: "download",
      });
    } else if (tache.status === StatutTache.ECHOUE) {
      TacheManager.relancer(tache.id)
        .then()
        .catch((erreur) => {
          HuitabToast.INSTANCE.toastme("La génération du fichier a échoué, veuillez réessayer plus tard");
        });
      TagCommanderManager.tagClick({
        chapitre_1: "vos_donnees_energetiques",
        chapitre_2: "vos_compteurs_prm",
        chapitre_3: "taches_en_cours",
        page: "relancer",
        type: "action",
      });
    }
  }

  /**
   * Affiche et cache la barre de navigation mobile
   */
  _changeToggleIconClass() {
    this.querySelector("#mobile-nav").classList.toggle("show");
    document.querySelector("body").classList.toggle("mobile-noscrollbar-bg");
    const nodeNavbarIcon = this.querySelector("#navbar-toggler-icon #show-icon");
    nodeNavbarIcon.classList.toggle("fa-bars");
    nodeNavbarIcon.classList.toggle("fa-times");
  }

  /**
   * Change l'icône de la liste des collectivités au déploiement
   */
  _changeCollectiveListIconClass() {
    const nodeIconeListeCollectivite = this.querySelector("#selected-collective span.icomoon");
    nodeIconeListeCollectivite.classList.toggle("icon-chevron-down");
    nodeIconeListeCollectivite.classList.toggle("icon-chevron-down1");
    this.querySelector("#liste-collectivite .dropdown-menu").style.width =
      this.querySelector("#selected-collective").offsetWidth + "px";
  }
}
