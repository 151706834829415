export default class LncWebSelect {
  constructor () {
    this.element = document.querySelector('lnc-web-select');

    this.element?.addEventListener("instance", (event) => {this.ref = event.detail});
  }

  writeValue(collectiviteSelected){
    this.ref.writeValue(collectiviteSelected);
  }

  setErrorMessage(errorMessage){
    this.ref.errorMessage=errorMessage;
  }

  removeError(){
    this.ref.errorMessage=undefined;
  }

  getSelectedValue(){
    return this.ref.selectedOption;
  }
}