import { HuitabModal } from '../../js/components/common/huitab-modal'
import { mesPrmsEntrepriseService } from '../../js/api-client'
import { StoreManager } from '../../js/services/store-manager'
import { componentSelectCollectivite, modalConfirmShowTasks } from './main'
import { TagCommanderManager } from "../../js/services/tag-commander-manager";
import { HuitabConstantes } from '../../js/services/enums'
import { TacheManager } from '../../js/services/tache-manager'

export default class ModalEcowattOrangeRouge extends HuitabModal {
  /**
   * Initialise une nouvelle instance de type {@type ModalTBD}.
   */
  constructor (typeModalEcoWattFeatureFlipping) {
    super(typeModalEcoWattFeatureFlipping)
    const handleModalActions = () => {
      componentSelectCollectivite.removeError();
      const toastError = document.getElementById("modal-ecowatt-rouge-orange-feature-flipping-on").querySelector("huitab-toast");
      toastError.hide();
    };
    this.onAccept(handleModalActions);
    this.onDismiss(handleModalActions);
  }

  /**
   * Demande d'export des prms concernés par un délestage
   */
  requestExportPrmsDelestes(){
    TagCommanderManager.tagClick({
      chapitre_1: "Ecowatt",
      chapitre_2: "pop_in",
      chapitre_3: "",
      page: "telecharger_la_liste",
      type: "download",
    });
    componentSelectCollectivite.removeError();
    const selectValue = componentSelectCollectivite.getSelectedValue();
    if (selectValue) {
      mesPrmsEntrepriseService
        .exportPrmsDelestes(StoreManager.cnAlex, selectValue.value.toLowerCase() === HuitabConstantes.SIREN_ALL ? StoreManager.getSelectedCollectivites().map(collectivite => collectivite.siren) : [selectValue.value], StoreManager.bandeauMeteoDateSelectionnee)
        .then(() => {
          this.hideSuccess();
          TacheManager._startInterval();
          componentSelectCollectivite.removeError();
          modalConfirmShowTasks.show();
        })
        .catch((e) => {
          const toastError = document.getElementById("modal-ecowatt-rouge-orange-feature-flipping-on").querySelector("huitab-toast");
          toastError.setToast("Echec du téléchargement", "true", "0", "M", "error_outline", 'blanc', 'rouge-500', 'Error');
          toastError.show();
          document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').getElementsByClassName("lnc-modal-content").item(0).scrollTop = 0;
        })
    } else {
      componentSelectCollectivite.setErrorMessage('Veuillez sélectionner au moins une collectivité')
      document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').setAttribute('is-modal-error', true)
    }
  }

  /**
   * Ouverture de la modal ecowatt
   */
  show () {
    super.show()
  }

  /**
   * Fermeture de la modal ecowatt avec émission d'un événement accept
   */
  hideSuccess () {
    super.hideOnAccept()
  }

  /**
   * Fermeture de la modal ecowatt avec émission d'un événement dismiss
   */
  hideDismiss () {
    super.hideOnDismiss()
  }
}