import { CodeFonctionnalites } from "../api-client/api-client-enums";
import { ComponentHelper } from "./component-helper";
import { CookieLegacy, CookieMicroapp } from "./cookie-microapp";
import { CookiePortail } from "./cookie-portail";
import { HuitabConstantes, StoreKeys } from "./enums";
import { PortailLocalStorage } from './portail-local-storage'

/**
 * Store pour le portail colloc
 * @constructor
 * @public
 */
class HuitabStoreManager {
  /**
   * Initialise une instance de type {@type HuitabStoreManager}
   */
  constructor() {
    /**
     * Les abonnées
     * @type {{}}
     * @private
     */
    this.subscribers = {};

    /**
     * Les données du store
     * @type {HuitabStoreData}
     * @private
     */
    this.store = {};
  }

  /**
   * Initialise les données du store
   * @public
   * @param {UserInstance} userInstance Les informations de l'utilisateur
   */
  initialize(userInstance) {
    // Configuration du cookie
    if (CookiePortail.interneId !== userInstance.cnAlex) {
      CookiePortail.reset();
      CookieMicroapp.reset();
      CookieLegacy.reset();
      PortailLocalStorage.reset();
    }
    CookiePortail.interneId = userInstance.cnAlex;
    CookiePortail.isMultiCollectivites = userInstance.perimetre.length > 1 ? "true" : "false";

    if (!CookiePortail.selectedSiren) {
      CookiePortail.selectedSiren = HuitabConstantes.SIREN_ALL;
      CookieMicroapp.selectedSiren = HuitabConstantes.SIREN_ALL;
      CookieLegacy.selectedSiren = HuitabConstantes.SIREN_ALL;
    }

    // Initialiser les donnes dans le store
    this.store = { ...userInstance };
    this.store.tachesTemp = PortailLocalStorage.taches ? PortailLocalStorage.taches : [];
    this.store.taches = { statutOk: true, liste: [] };
    this.store.selectedSiren = CookiePortail.selectedSiren;
    this.store.widgetVisibilityPerimetre = this._buildWidgetVisibilityPerimetre(userInstance.perimetre);
    this.store.migrationAskLater = CookiePortail.migrationAskLater === "true";
    this.store.externalLinkAskLater = CookiePortail.externalLinkAskLater === "true";
    this.store.bandeauMeteoDateSelectionnee = CookiePortail.bandeauMeteoDateSelectionnee;
    this.store.bandeauMeteoDateSelectionneeFormatee = CookiePortail.bandeauMeteoDateSelectionneeFormatee;
    this.store.bandeauMeteoCouleur = CookiePortail.bandeauMeteoCouleur;
    this.store.isMultiCollectivites = CookiePortail.isMultiCollectivites === "true";

    // Notifier les abonnés après avoir initialiser le store
    this._publish(StoreKeys.USER_INFO, this.store.userInfoDto);
    this._publish(StoreKeys.PERIMETRE, this.store.perimetre);
    this._publish(StoreKeys.NB_DEMANDES_EN_COURS, this.store.nbDemandesEnCours);
    this._publish(StoreKeys.SELECTED_SIREN, this.store.selectedSiren);
    this._publish(StoreKeys.BANDEAU_METEO, this.store.bandeauMeteoDateSelectionnee);
    this._publish(StoreKeys.TACHES_TEMP, this.store.tachesTemp);
  }

  /**
   * Souscription aux events du store
   * @public
   * @param {StoreKeys} channel Evènement auquel s'abonner
   * @param {*} sub Callback à déclencher
   */
  subscribe(channel, sub) {
    this.subscribers[channel] = this.subscribers[channel] || [];
    this.subscribers[channel].push(sub);
  }

  /**
   * Action pour vérifier si l'utilisateur avait accédé au portail par le passé.
   * @return {boolean} Une valeur indiquant si l'utilisateur avait accédé au portail par le passé
   */
  wasConnected() {
    const cnAlex = CookiePortail.interneId;
    return typeof cnAlex !== "undefined" && cnAlex !== "null";
  }

  /**
   * Action pour vider le store et les informations persistées pour l'utilisateur
   */
  delete() {
    this.store = {};
    CookiePortail.delete();
    CookieMicroapp.delete();
    CookieLegacy.delete();
    PortailLocalStorage.delete();
  }

  /**
   * Récupération des départements correspondant à la collectivité sélectionnée
   *
   * @returns {string[]} la liste des départements sans doublons
   */
  getSelectedDepartements() {
    return StoreManager.selectedSiren === HuitabConstantes.SIREN_ALL
      ? [...new Set(StoreManager.perimetre.flatMap(perimetre => perimetre.departements))]
      : StoreManager.perimetre.find(perimetre => perimetre.siren === this.selectedSiren).departements;
  }

  /**
   * Récupération des collectivités correspondant à la collectivité sélectionnée
   *
   * @return {PerimetreDto[]} Le perimetre de l'utilisateur
   */
  getSelectedCollectivites() {
    return StoreManager.selectedSiren === HuitabConstantes.SIREN_ALL
      ? [...new Set(StoreManager.perimetre.flatMap(perimetre => perimetre))]
      : StoreManager.perimetre.find(perimetre => perimetre.siren === this.selectedSiren);
  }

  /**
   * Publication des events du store
   * @private
   * @param {StoreKeys} channel Evenement publié
   * @param {*} args Paramètres associés à l'évènement
   */
  _publish(channel, ...args) {
    (this.subscribers[channel] || []).forEach(sub => sub(...args));
  }

  /**
   * Construction des droits de visibilité des widgets pour l'utilisateur
   * @private
   * @param {PerimetreDto[]} perimetre Le perimetre de l'utilisateur
   * @return {WidgetVisibilityPerimetre} widgetVisibilityPerimetre Droits de visibilité des widgets pour l'utilisateur en fonction du siren
   */
  _buildWidgetVisibilityPerimetre(perimetre) {
    let widgetVisibilityPerimetre = {
      all: {
        travaux: false,
        mesures: false,
        coupures: false,
      },
    };

    perimetre.forEach(perimetreDto => {
      let widgetVisibility = {
        travaux: perimetreDto.fonctionnalites.includes(CodeFonctionnalites.INFO_TRAVAUX),
        mesures:
          perimetreDto.fonctionnalites.includes(CodeFonctionnalites.SDD_ADMIN) ||
          perimetreDto.fonctionnalites.includes(CodeFonctionnalites.SDD_STANDARD),
        coupures: perimetreDto.fonctionnalites.includes(CodeFonctionnalites.INFO_COUPURES),
      };

      widgetVisibilityPerimetre.all.travaux = widgetVisibilityPerimetre.all.travaux || widgetVisibility.travaux;
      widgetVisibilityPerimetre.all.mesures = widgetVisibilityPerimetre.all.mesures || widgetVisibility.mesures;
      widgetVisibilityPerimetre.all.coupures = widgetVisibilityPerimetre.all.coupures || widgetVisibility.coupures;

      widgetVisibilityPerimetre[perimetreDto.siren] = widgetVisibility;
    });

    return widgetVisibilityPerimetre;
  }

  /**
   * Get l'identifiant alex de la personne connectée
   * @return {string} L'identifiant alex de la personne connectée
   */
  get cnAlex() {
    return this.store.cnAlex;
  }

  /**
   * Get les informations de l'utilisateur
   * @return {UserInfoDto} Les informations de l'utilisateur
   */
  get userInfo() {
    return this.store.userInfoDto;
  }

  /**
   * Set les informations de l'utilisateur
   * @param {UserInfoDto} value Les informations de l'utilisateur
   */
  set userInfo(value) {
    this.store.userInfoDto = value;
    this._publish(StoreKeys.USER_INFO, value);
  }

  /**
   * Get le perimetre de l'utilisateur
   * @return {PerimetreDto[]} Le perimetre de l'utilisateur
   */
  get perimetre() {
    return this.store.perimetre;
  }

  /**
   * Set le perimetre de l'utilisateur
   * @param {PerimetreDto[]} value Le perimetre de l'utilisateur
   */
  set perimetre(value) {
    this.store.perimetre = value;
    this.store.widgetVisibilityPerimetre = this._buildWidgetVisibilityPerimetre(value);
    this._publish(StoreKeys.PERIMETRE, value);
  }

  /**
   * Get le nombre de demandes en cours
   * @return {number} Le nombre de demandes en cours
   */
  get nbDemandesEnCours() {
    return this.store.nbDemandesEnCours;
  }

  /**
   * Set le nombre de demandes en cours
   * @param {number} value Le nombre de demandes en cours
   */
  set nbDemandesEnCours(value) {
    this.store.nbDemandesEnCours = value;
    this._publish(StoreKeys.NB_DEMANDES_EN_COURS, value);
  }

  /**
   * Get le siren sélectionné
   * @return {string} Le siren sélectionné
   */
  get selectedSiren() {
    return this.store.selectedSiren;
  }

  /**
   * Set le siren sélectionné
   * @param {string} value Le siren sélectionné
   */
  set selectedSiren(value) {
    if (this.store.selectedSiren === value) {
      // Rien faire si pas de changement de siren
      return;
    }

    this.store.selectedSiren = value;
    CookiePortail.selectedSiren = value;
    CookieMicroapp.selectedSiren = value;
    CookieLegacy.selectedSiren = value;
    ComponentHelper.pymSendMessage("majPerimetre", "readCookie");
    this._publish(StoreKeys.SELECTED_SIREN, value);
  }

  /**
   * Get une valeur indiquant si la migration doit reprendre plus tard.
   * @return {boolean} Une valeur indiquant si la migration doit reprendre plus tard.
   */
  get migrationAskLater() {
    return this.store.migrationAskLater;
  }

  /**
   * Set une valeur indiquant si la migration doit reprendre plus tard.
   * @param {boolean} value La valeur indiquant si la migration doit reprendre plus tard.
   */
  set migrationAskLater(value) {
    this.store.migrationAskLater = value;
    CookiePortail.migrationAskLater = value.toString();
  }

  /**
   * Get une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @return {boolean} Une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  get externalLinkAskLater() {
    return this.store.externalLinkAskLater;
  }

  /**
   * Set valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @param {boolean} value La valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  set externalLinkAskLater(value) {
    this.store.externalLinkAskLater = value;
    CookiePortail.externalLinkAskLater = value.toString();
  }

  /**
   * Get la visibilité des widgets sur le périmètre de l'utilisateur
   * @return {WidgetVisibilityPerimetre}
   */
  get widgetVisibilityPerimetre() {
    return this.store.widgetVisibilityPerimetre;
  }

  /**
   * Get la date sélectionnée dans le bandeau météo.
   * @return {string|null} La date sélectionnée dans le bandeau météo.
   */
  get bandeauMeteoDateSelectionnee() {
    return this.store.bandeauMeteoDateSelectionnee;
  }

  /**
   * Set la date sélectionnée dans le bandeau météo.
   * @param {string} value La date sélectionnée dans le bandeau météo.
   */
  set bandeauMeteoDateSelectionnee(value) {
    this.store.bandeauMeteoDateSelectionnee = value;
    CookiePortail.bandeauMeteoDateSelectionnee = value.toString();
  }

  /**
   * Get la date sélectionnée formatée dans le bandeau météo.
   * @return {string|null} La date sélectionnée dans le bandeau météo.
   */
  get bandeauMeteoDateSelectionneeFormatee() {
    return this.store.bandeauMeteoDateSelectionneeFormatee;
  }

  /**
   * Set la date sélectionnée formatée dans le bandeau météo.
   * @param {string} value La date sélectionnée dans le bandeau météo.
   */
  set bandeauMeteoDateSelectionneeFormatee(value) {
    this.store.bandeauMeteoDateSelectionneeFormatee = value;
    CookiePortail.bandeauMeteoDateSelectionneeFormatee = value.toString();
  }

  /**
   * Get la couleur sélectionnée dans le bandeau météo.
   * @return {ColorEcoWatt|null} La couleur sélectionnée dans le bandeau météo.
   */
  get bandeauMeteoCouleur() {
    return this.store.bandeauMeteoCouleur;
  }

  /**
   * Set la couleur sélectionnée dans le bandeau météo.
   * @param {ColorEcoWatt} value La couleur sélectionnée dans le bandeau météo.
   */
  set bandeauMeteoCouleur(value) {
    this.store.bandeauMeteoCouleur = value;
    CookiePortail.bandeauMeteoCouleur = value.toString();
  }

  /**
   * Get un booléen indiquant si le compte est multi-collectivités (périmètre intégrant plusieurs siren)
   * @return {boolean|null} Le périmètre du compte comprend plusieurs siren (compte multi-collectivités)
   */
  get isMultiCollectivites() {
    return this.store.isMultiCollectivites;
  }

  /**
   * Set le booléen indiquant si le compte est multi-collectivités (périmètre intégrant plusieurs siren)
   * @param {boolean} value Le périmètre du compte comprend plusieurs siren (compte multi-collectivités)
   */
  set isMultiCollectivites(value) {
    this.store.isMultiCollectivites = value;
    CookiePortail.isMultiCollectivites = value.toString();
  }

  /**
   * Get les taches de l'utilisateur
   * @return {TacheGroupDto} Les taches de l'utilisateur
   */
  get taches() {
    return this.store.taches;
  }

  /**
   * Set les taches de l'utilisateur
   * @param {TacheGroupDto} value Les taches de l'utilisateur
   */
  set taches(value) {
    this.store.taches = value;
    this._publish(StoreKeys.TACHES, value);
  }

  /**
   * Get les taches temporaires de l'utilisateur
   * @return {TacheDto[]} La liste des taches temporaires de l'utilisateur
   */
  get tachesTemp() {
    return this.store.tachesTemp;
  }

  /**
   * Set les taches temporaires de l'utilisateur
   * @param {TacheDto[]} value La liste des taches temporaires de l'utilisateur
   */
  set tachesTemp(value) {
    this.store.tachesTemp = value;
    PortailLocalStorage.taches = value;
    this._publish(StoreKeys.TACHES_TEMP, value);
  }
}

const StoreManager = new HuitabStoreManager();
export { StoreManager };
