import { PageManager } from "../../services/page-manager";
import MicroApp from "./micro-app-base.js";

/**
 * Microapp migration de compte
 */
export default class CartographieReseauMicroApp extends MicroApp {
  childPymEvents = {
    /**
     * Configurer la hauteur de l'iframe à la demande de la microapp si elle est supérieure à la hauteur minimale
     * @param {string} height La hauteur de l'iframe
     * @override
     */
    height: height => {
      const minHeight = Math.max(parseInt(PageManager.current.microapp.iframeMinHeight), parseInt(height));
      this.style.height = minHeight + "px";
      const iframe = this.querySelector("iframe");
      if (iframe) {
        iframe.style.height = minHeight + "px";
        iframe.removeAttribute("height");
      }
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type MigrationCompteMicroApp}.
   */
  constructor() {
    super();
  }

  displayOnReady() {
    super.displayOnReady();
    this.style.height = PageManager.current.microapp.iframeMinHeight + "px";
  }
}
