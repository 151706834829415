import { LncBaseApiClientService } from '@odw/lnc-services/js-services/api-client/lnc-base-api-client.service'

/**
 * Service pour réaliser les appels à l'api passage des hivers
 */
export default class PassageHiverService extends LncBaseApiClientService {
  /**
   * Retourne les informations des jours J+4 de ecowatt
   * @returns {Promise<InfoDayEcoWatt[]>} La promesse d'une réponse.
   */
  getInfoDaysEcowatt() {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/signaux-ecowatt")
        .then(response => {
          resolve(response.content);
        })
        .catch(() => {
          reject();
        });
    });
  }
}
