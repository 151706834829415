import MicroApp from "./micro-app-base";
import { HuitabPageManager } from "../../services/page-manager";
import { mesCollectivitesService, monCompteClientService } from "../../api-client/index";
import { StoreManager } from "../../services/store-manager";

/**
 * Microapp gestion de compte
 */
export default class GestionCompteMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    /**
     * Réaction à un changement de tab.
     * @param {string} idTab L'identifiant du tab.
     * Valeurs = [Profil, Demandes, Perimetre, DesactivationCompte]
     */
    changementTabUser: function (idTab) {
      document.querySelectorAll("a[href^='/gestion-compte']").forEach(node => {
        node.classList.remove("active", "is-active");
      });
      document.querySelectorAll("a[href='/gestion-compte?idTab=" + idTab + "']").forEach(node => {
        node.classList.add("active", "is-active");
      });
      HuitabPageManager.updateUrlFromIdTab(idTab);
    },
    /**
     * Event pour mettre à jour les informations de la personne connectée
     */
    changementIdentifiantUser: function () {
      monCompteClientService.getUserInfosV2().then(response => {
        StoreManager.userInfo = response;
      });
    },
    /**
     * Event pour mettre à jour le nombre de demandes en cours
     */
    changementNbDemandes: function () {
      mesCollectivitesService.getNbDemandesEnCours(StoreManager.cnAlex).then(response => {
        StoreManager.nbDemandesEnCours = response;
      });
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type GestionCompteMicroApp}.
   */
  constructor() {
    super();
  }
}
