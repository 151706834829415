import { TagCommanderManager } from "../../services/tag-commander-manager";
import ModalBase from "./modal-base";

/**
 * La modal pour les popin de non cartographie
 */
export default class ModalPopinNoCarto extends ModalBase {
  /**
   * Initialise une nouvelle instance de type {@type ModalPopinNoCarto}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir à l'ouverture de la modal
   * @override
   */
  _onShowModal() {
    TagCommanderManager.tagPage({
      chapitre_1: "Ecowatt",
      chapitre_2: "cartes_des_zones_delestees",
      chapitre_3: "page_accueil",
      page: "pas_de_cartographie",
    });
  }

  /**
   * Action pour réagir au clic FERMER
   * @override
   */
  _onAccept() {
    this._closeModal();
  }

  /**
   * Action pour réagir au clic sur la croix.
   * @override
   */
  _onDismiss() {
    this._closeModal();
  }
}
