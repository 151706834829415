/**
 * Les valeurs possibles pour l'enum StatutDemande
 */
class StatutDemande {
  static ENCOURS = new StatutDemande("ENCOURS");
  static VALIDEE = new StatutDemande("VALIDEE");
  static REFUSEE = new StatutDemande("REFUSEE");

  /**
   * Initialise une instance de type {@type StatutDemande}.
   * @private
   * @param name {string} le statut.
   */
  constructor(name) {
    this.name = name;
  }
}

/**
 * Les valeurs possibles pour l'enum CodeFonctionnalites
 */
const CodeFonctionnalites = {
  CARTO_NON_AODE: "CARTO_NON_AODE",
  SDD_STANDARD: "SERVICES_DE_DONNEES",
  SDD_ADMIN: "SDD_ADMIN",
  CRAC: "CRAC",
  INFO_TRAVAUX: "INFO_TRAVAUX",
  INFO_COUPURES: "INFO_COUPURES",
  RACCORDEMENTS: "RACCORDEMENTS",
  TDB_CONCESSION: "TDB_CONCESSION",
  CARTO_AODE: "CARTO_AODE",
};

/**
 * Les valeurs possibles pour l'enum TypeChantier
 */
class TypeChantier {
  static RACCORDEMENT_CLIENT = new TypeChantier("RACCORDEMENT_CLIENT", "Raccordement client");
  static MODIFICATION_OUVRAGE = new TypeChantier("MODIFICATION_OUVRAGE", "Modification d’ouvrage");
  static MODERNISATION_RESEAU = new TypeChantier("MODERNISATION_RESEAU", "Modernisation du réseau");

  /**
   * Initialise une instance de type {@type TypeChantier}.
   * @private
   * @param name {string} La valeur de l'enum.
   * @param label {string} Le label.
   */
  constructor(name, label) {
    this.name = name;
    this.label = label;
  }
}

/**
 * Les valeurs possibles pour l'enum StatutChantier
 */
class StatutChantier {
  static PREPARATION_TRAVAUX = new StatutChantier("PREPARATION_TRAVAUX", "Préparation travaux");
  static TRAVAUX_EN_COURS = new StatutChantier("TRAVAUX_EN_COURS", "Travaux en cours");
  static OUVRAGE_ELECTRIQUE_CONSTRUIT = new StatutChantier(
    "OUVRAGE_ELECTRIQUE_CONSTRUIT",
    "Ouvrage électrique construit"
  );
  static CHANTIER_ET_TRAVAUX_TERMINES = new StatutChantier(
    "CHANTIER_ET_TRAVAUX_TERMINES",
    "Chantiers et travaux terminés"
  );

  /**
   * Initialise une instance de type {@type StatutChantier}.
   * @private
   * @param name {string} La valeur de l'enum.
   * @param label {string} Le label.
   */
  constructor(name, label) {
    this.name = name;
    this.label = label;
  }
}

export { StatutDemande, CodeFonctionnalites, TypeChantier, StatutChantier };
