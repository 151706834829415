import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";

/**
 * Service pour réaliser les appels à l'api de WEBDELEST
 */
export default class CartoDelestageService extends LncBaseApiClientService {
    /**
     * Retourne le code de retour lors du ping de webdelest
     * @param {string} siren Le siren utilisé pour ping WEBDELEST
     * @param {string} codeInsee Le codeInsee utilisé pour ping WEBDELEST
     * @param {string} date La date utilisée pour ping WEBDELEST
     * @returns {Promise<number>} La promesse d'un statut
     */
    pingCartoDelestage(siren, codeInsee, date) {
        return new Promise((resolve, reject) => {
            this.get("/" + siren + "/" + codeInsee + "/" + date)
                .then(response => {
                    resolve(response.status);
                })
                .catch(e => {
                    resolve(e.status);
                });
        });
    }
}
